import React, { Suspense, lazy, Component } from "react"
import { Route, Switch } from "react-router-dom"
import "./App.css"
const Me = lazy(() => import("./services/Me"))
const Nav = lazy(() => import("./components/Nav"))
const SubjectGroup = lazy(() => import("./components/SubjectGroup"))
const SignInForm = lazy(() => import("./components/SignInForm"))
const RegisterForm = lazy(() => import("./components/RegisterForm"))
const SignOut = lazy(() => import("./components/SignOut"))
const CreateProfile = lazy(() => import("./components/Profile/CreateProfile"))
const ViewProfile = lazy(() => import("./components/Profile/ViewProfile"))
const UpdateProfileView = lazy(() => import("./components/Profile/UpdateProfileView"))
const Aim = lazy(() => import("./components/Aim"))
const AimPaper = lazy(() => import("./components/AimPaper"))
const RegistrationSuccess = lazy(() => import("./components/RegistrationSuccess"))
const ProfileUpload = lazy(() => import("./components/ProfileUpload"))
const EBShortProfileQuery = lazy(() => import("./components/EBProfile/EBShortProfileQuery"))
const EBProfileQuery = lazy(() => import("./components/EBProfile/EBProfileQuery"))
const AimKey = lazy(() => import("./components/AimKey"))
const CreateManuscript = lazy(() => import("./components/Tracking/ManuscriptSubmission/CreateManuscript"))
const ViewManuscriptSubmission = lazy(() => import("./components/Tracking/ManuscriptSubmission/ViewManuscriptSubmission"))
const UploadManuscript = lazy(() => import("./components/Tracking/ManuscriptSubmission/UploadManuscript"))
const ViewManuscript = lazy(() => import("./components/Tracking/ManuscriptSubmission/ViewManuscript"))
const UpdateManuscripView = lazy(() => import("./components/Tracking/ManuscriptSubmission/UpdateManuscripView"))
const Footer = lazy(() => import("./components/common/Footer"))
const ReviewerShortProfileQuery = lazy(() => import("./components/ReviewerProfile/ReviewerShortProfileQuery"))
const CreateReview = lazy(() => import("./components/Tracking/Review/CreateReview"))
const UpdateReviewView = lazy(() => import("./components/Tracking/Review/UpdateReviewView"))
const CreateComment = lazy(() => import("./components/Tracking/Review/CreateComment"))
const ViewAllReviews = lazy(() => import("./components/Tracking/Review/ViewAllReviews"))
const MyReviewList = lazy(() => import("./components/Tracking/Review/MyReviewList"))
const CreateUploadReview = lazy(() => import("./components/Tracking/Review/CreateUploadReview"))
const InstructionsAuthor = lazy(() => import("./components/pages/InstructionsAuthor"))
const InstructionsReviewer = lazy(() => import("./components/pages/InstructionsReviewer"))
const AccessCodeEntry = lazy(() => import("./components/Tracking/Review/AccessCodeEntry"))
const EBShortProfileQueryNext = lazy(() => import("./components/EBProfile/EBShortProfileQueryNext"))
const EBShortProfileQueryPrevious = lazy(() => import("./components/EBProfile/EBShortProfileQueryPrevious"))
const PayNow = lazy(() => import("./components/Tracking/CrowndfundingPayment/PayNow"))
const AimTrack = lazy(() => import("./components/PageViewsTracking/AimTrack"))
const InstructionsAuthorTracking = lazy(() => import("./components/PageViewsTracking/InstructionsAuthorTracking"))
const InstructionsReviewerTracking = lazy(() => import("./components/PageViewsTracking/InstructionsReviewerTracking"))
const SpecialIssueList = lazy(() => import("./components/pages/SpecialIssueList"))
const RenalDiseases = lazy(() => import("./components/specialIssues/RenalDiseases"))
const RenalDiseasesTrack = lazy(() => import("./components/PageViewsTracking/RenalDiseasesTrack"))
const FeedbackForm = lazy(() => import("./components/PageViewsTracking/FeedbackForm"))
const ThankYou = lazy(() => import("./components/PageViewsTracking/ThankYou"))
const MediaNetAds = lazy(() => import("./components/tags/MediaNetAds"))
const ArticleListView = lazy(() => import("./components/biohelikon/ArticleListView"))
const ArticleDetailsView = lazy(() => import("./components/biohelikon/ArticleDetailsView"))
const InstructionsAuthorTrackCipher = lazy(() => import('./components/PageViewsTracking/InstructionsAuthorTrackCipher'))

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Me>
        {({ data }) => {
          let me = {}
          if (data) {
            me = data.me
          }

          return (
            <React.Fragment>
              <div>
                <div id="header">
                  <Nav me={me} />
                </div>
                <main role="main" className="flex-shrink-0">
                  <div className="text-center">
                    <MediaNetAds />
                  </div>
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Switch>
                        <Route path="/" exact component={Aim} />
                        <Route path="/subjects" component={SubjectGroup} /> {!data && <Route path="/signin" component={SignInForm} />}
                        {!data && <Route path="/register" component={RegisterForm} />}
                        {!data && <Route path="/registercomplete" component={RegistrationSuccess} />}
                        {data && <Route path="/signout" component={SignOut} />}
                        {data && <Route path="/createprofile" component={CreateProfile} />}
                        {data && <Route path="/imageupload" component={ProfileUpload} />}
                        {data && <Route path="/profile" component={ViewProfile} />}
                        {data && <Route path="/updateprofile" component={UpdateProfileView} />}
                        <Route path="/aim" component={Aim} />
                        <Route path="/authors/instructions" component={InstructionsAuthor} />
                        <Route path="/reviewers/instructions" component={InstructionsReviewer} />
                        <Route path="/ebprofiledetails/:id" component={EBProfileQuery} />
                        <Route path="/ebprofiles" exact component={EBShortProfileQuery} />
                        <Route path="/ebprofiles/next/:id" component={EBShortProfileQueryNext} />
                        <Route path="/ebprofiles/previous/:id" component={EBShortProfileQueryPrevious} />
                        <Route path="/reviewerprofiles" component={ReviewerShortProfileQuery} />
                        <Route path="/home/:key/" component={AimKey} />
                        <Route path="/createsubmission" component={CreateManuscript} />
                        <Route path="/viewsubmissions" component={ViewManuscriptSubmission} />
                        <Route path="/uploadsubmission/:id" component={UploadManuscript} />
                        <Route path="/viewmanuscript/:id" component={ViewManuscript} />
                        <Route path="/updatemanuscript/:id" component={UpdateManuscripView} />
                        <Route path="/createreview/:id" component={CreateReview} />
                        <Route path="/updatereview/:id" component={UpdateReviewView} />
                        <Route path="/createcomment/:id" component={CreateComment} />
                        <Route path="/review/:id" component={ViewAllReviews} />
                        <Route path="/reviewarticles" component={MyReviewList} />
                        <Route path="/enteraccesscode" component={AccessCodeEntry} />
                        <Route path="/uploadreview/:id" component={CreateUploadReview} />
                        <Route path="/specialissues" component={SpecialIssueList} />
                        <Route path="/renaldiseases" component={RenalDiseases} />
                        <Route path="/rd/:key" component={RenalDiseasesTrack} />
                        <Route path="/pay" component={PayNow} />
                        <Route path="/homepage/:key" component={AimTrack} />
                        <Route path="/ait/:key" component={InstructionsAuthorTracking} />
                        <Route path="/rit/:key" component={InstructionsReviewerTracking} />
                        <Route path="/feedback/:key" component={FeedbackForm} />
                        <Route path="/feedback" component={FeedbackForm} />
                        <Route path="/thankyou" component={ThankYou} />
                        <Route path="/articlelist" component={ArticleListView} />
                        <Route path="/articleview/:id/:slug" component={ArticleDetailsView} />
                        <Route path="/aui/:key+" component={InstructionsAuthorTrackCipher} />
                      </Switch>
                    </Suspense>
                  </div>
                </main>
              </div>
              <Footer />
            </React.Fragment>
          )
        }}
      </Me>
    </Suspense>
  )
}

export default App
